.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(0px)
  }
}

.transition-group {
  position: relative
}


// swipe-top
.swipe-top-enter,
.swipe-top-exit {
  transition: transform 300ms ease-out;
}

.swipe-top-enter {
  opacity: 0.01;
  transform: translateY(100%);
}

.swipe-top-enter.swipe-top-enter-active {
  opacity: 1;
  transform: translateY(0%);
}

.swipe-top-exit {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(0%);
}

.swipe-top-exit-active {
  opacity: 0.01;
  transform: translateY(-100%);
}

// swipe-bottom
.swipe-bottom-enter,
.swipe-bottom-exit {
  transition: transform 300ms ease-out;
}

.swipe-bottom-enter {
  opacity: 0.01;
  transform: translateY(-100%);
}

.swipe-bottom-enter.swipe-bottom-enter-active {
  opacity: 1;
  transform: translateY(0%);
}

.swipe-bottom-exit {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(0%);
}

.swipe-bottom-exit-active {
  opacity: 0.01;
  transform: translateY(100%);
}
